//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange } from "@/commons";
export default {
  name: "ReportData",
  props: {
    reportData: {
      type: Object,
      default: null,
    },
    isCompany: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeReportTab: "总",
      reportTabs: ["今", "总"],
    };
  },
  methods: {
    imgChange,
    formatReportStatus(status) {
      return this.$D.ITEM("report_status", status).name;
    },
    handleTabClick(tab) {
      this.activeReportTab = tab;
    },
  },
};
